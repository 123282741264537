import { MinnieCup, KoFiLogo } from './../assets';
import './../App.css';

function Support() {
    return (
        <div id="support" className="support-container">
            <h1 className="section-title">Support</h1>

            <p className="slide-left">
                Thank you sincerely everyone for your support, without you all, I don't know where I'd be, and for that, I'd like you to know every bit of support brings a smile to my face, thank you for believing in me!
            </p>

            <p className="slide-left">
                Please note that you do not need to give me anything.
            </p>

            <div className="support-main">
                <div className="support-img-container">
                    <h2 className="sub-section-title">Ko-Fi</h2>

                    <a href="https://ko-fi.com/minnieful" target="_blank" rel="noopener noreferrer">
                        <img className="donation-img" src={KoFiLogo} height="500px" width="500px" alt="Ko-Fi" />
                    </a>
                </div>

                <div className="support-img-container">
                    <h2 className="sub-section-title">Merch</h2>

                    <a href="https://streamlabs.com/minnieful/merch" target="_blank" rel="noopener noreferrer">
                        <img className="donation-img" src={MinnieCup} height="500px" width="500px" alt="Merch" />
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Support;