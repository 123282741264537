import { MakeLifeMinnieful, MinnieUnderstood } from './../assets';
import './../App.css';

function TOS() {
    return (
        <div id="tos" className="tos-container">
            <h1 className="section-title">Terms of Service</h1>

            <div className="tos-img-container">
                <img className="tos-img" src={MakeLifeMinnieful} alt="Make Life Minnieful" height="320px" width="294px" />
            </div>

            <p className="slide-left">
                By commissioning me (Minnieful), the client indicates that they have read, understood and consented to these terms of service.
            </p>

            <h2 className="sub-section-title">General Terms</h2>

            <ul className="list">
                <li className="list-item slide-left">
                    Please be respectful of me and my work and use it in a respectful way.
                </li>
                <li className="list-item slide-left">
                    All of my commissions are digital. I do not ship anything physical.
                </li>
                <li className="list-item slide-left">
                    The client may not claim the art as their own and must always provide credit for the art wherever applicable.
                </li>
                <li className="list-item slide-left">
                    The client must recieve my approval before making any modifications of the art I have created.
                    <br />
                    I retain the right to prohibit any edits I am not comfortable with.
                </li>
                <li className="list-item slide-left">
                    Under no circumstances do I permit my work to be used in a way that would taint my public image.
                    All work must be used in a respectful manner.
                </li>
                <li className="list-item slide-left">
                    The client maintains the rights to any characters which rightfully belong to them.
                </li>
                <li className="list-item slide-left">
                    All usage of my artwork for Artificial Intelligence learning is prohibited.
                    Under no circumstances do I permit my work to be used in markets that make use of blockchain technology.
                    This includes any form of cryptocurrency (such as Bitcoin or Litecoin) and non-fungible tokens (NFTs).
                </li>
                <li className="list-item slide-left">
                    I reserve the right to deny any commission requests.
                </li>
                <li className="list-item slide-left">
                    If the client does not want the creation process of their commission to be live streamed, they must say so before the initial payment is made.
                    Otherwise, I reserve the right live stream the entire creation process of any art commissioned.
                </li>
            </ul>

            <h2 className="sub-section-title">Pricing</h2>

            <ul className="list">
                <li className="list-item slide-left">
                    All prices are in United States Dollars (USD).
                </li>
                <li className="list-item slide-left">
                    The client must pay for the commission upfront in a one-time payment.
                </li>
                <li className="list-item slide-left">
                    Payments are only accepted via Ko-Fi and Paypal's invoice systems.
                </li>
                <li className="list-item slide-left">
                    If a project is too complex, I may adjust the price to a more fitting amout.
                </li>
                <li className="list-item slide-left">
                    Commission prices are subject to change at any time. Ongoing commissions will not be affected by any changes in pricing before its completion.
                </li>
                <li className="list-item slide-left">
                    I reserve the right to adjust individual commission prices according to the complexity of the client's request.
                    “Complexity” here refers to any additional time and work needed in order to meet the client's vision, and will encompass work such as rendering a complex piece of armor.
                    What counts as complex will be discussed beforehand.
                </li>
                <li className="list-item slide-left">
                    Due to the nature of invoicing, I may be able to see the client's personal details such as the client's real name.
                    I will not publicise personal information under any circumstances, save for if there is a threat to someone's personal safety (the client's or another's), and I expect my clients to maintain my privacy as well.
                </li>
            </ul>

            <h2 className="sub-section-title">Cancellation and Refunds</h2>

            <ul className="list">
                <li className="list-item slide-left">
                    I reserve the right to cancel a commission at any time, for any reason, regardless of how far along the commission is.
                    <br />
                    If the commission is cancelled due to my inability to complete the commission, the client will be given a full refund.
                </li>
                <li className="list-item slide-left">
                    I reserve the right to cancel a commission due to rude or inappropriate behavior from the client, or any other similar circumstances that I will not tolerate.
                    <br />
                    In these cases, the commission will be canceled and no refund will be given.
                </li>
                <li className="list-item slide-left">
                    If a commission has been completed, it will no longer be possible to cancel the commission and no refunds will be given.
                </li>
                <li className="list-item slide-left">
                    If a commission has not been completed, the client may cancel a commission in progress at any time.
                </li>
                <li className="list-item slide-left">
                    In the case of the client cancelling a commission, only 20% of what was paid will be refunded to the client, regardless of how far along the commission is.
                </li>
                <li className="list-item slide-left">
                    The client is required to inform me of any intent to sell any of the art they have commissioned from me.
                    The client is not allowed to sell any art without my permission.
                </li>
            </ul>

            <h2 className="sub-section-title">Revisions</h2>

            <ul className="list">
                <li className="list-item slide-left">
                    Major revisions include changes which will require large parts of the image to be redrawn.
                </li>
                <li className="list-item slide-left">
                    Minor revisions include small adjustments that will not require heavy redrawing.
                </li>
                <li className="list-item slide-left">
                    Major revisions may be charged based on the complexity of the revision.
                </li>
                <li className="list-item slide-left">
                    Minor revisions are free of charge up to a certain limit. This limit is up to my own discretion.
                    <br />
                    If the number of requested minor revisions becomes too great, the client will be charged additional fees.
                </li>
            </ul>
            <br />

            <img className="tos-img understood-img" src={MinnieUnderstood} alt="Understood" height="510px" width="510px" />
        </div>
    );
}

export default TOS;