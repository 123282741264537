import { Carousel } from 'react-responsive-carousel';
import {
    AngChaos, AngCry, AngHi, AngRaid,
    CrizzHi, CrizzHide,
    CryptCry, CryptHeart, CryptRun,
    MadruckComfy, MadruckHeart, MadruckHelp, MadruckMad, MadruckPout, MadruckThreaten,
    MinnieEhe, MinnieGremlin, MinnieHype, MinnieLove, MinniePog, MinnieSmug,
    RuneShrug, RuneSigh, RuneSleep,
    TempestDespair, TempestBullied, TempestLove, TempestMadge, TempestSleep
} from './../assets/emotes';
import {
    Chihiroo1, Chihiroo2, Chihiroo3, Chihiroo4, ChihirooAnimated,
    Madruck1, Madruck2, Madruck3, Madruck4, MadruckAnimated,
    Rune1, Rune2, Rune3, Rune4, RuneAnimated
} from './../assets/models';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './../Portfolio.css';
import './../App.css';

function Portfolio() {
    return (
        <div id="portfolio" className="portfolio-container">
            <h1 className="section-title">Portfolio</h1>

            <h2 className="sub-section-title">Emotes</h2>

            <div className="section-container">
                <div className="emote-carousel">
                    <Carousel
                        autoPlay={true}
                        infiniteLoop={true}
                        showStatus={false}
                        showIndicators={false}
                        stopOnHover={true}
                        centerSlidePercentage={true}
                    >
                        <img className="emote-img" src={AngChaos} height="500px" width="500px" alt="Angowo Chaos" />
                        <img className="emote-img" src={AngCry} height="500px" width="500px" alt="Angowo Cry" />
                        <img className="emote-img" src={AngHi} height="500px" width="500px" alt="Angowo Hi" />
                        <img className="emote-img" src={AngRaid} height="500px" width="500px" alt="Angowo Raid" />
                        <img className="emote-img" src={CrizzHi} height="500px" width="500px" alt="zirCrizz Hi" />
                        <img className="emote-img" src={CrizzHide} height="500px" width="500px" alt="zirCrizz Hide" />
                        <img className="emote-img" src={CryptCry} height="500px" width="500px" alt="Crypt Cry" />
                        <img className="emote-img" src={CryptHeart} height="500px" width="500px" alt="Crypt Heart" />
                        <img className="emote-img" src={CryptRun} height="500px" width="500px" alt="Crypt Run" />
                        <img className="emote-img" src={MadruckComfy} height="500px" width="500px" alt="Madrucksack Comfy" />
                        <img className="emote-img" src={MadruckHeart} height="500px" width="500px" alt="Madrucksack Heart" />
                        <img className="emote-img" src={MadruckHelp} height="500px" width="500px" alt="Madrucksack Help" />
                        <img className="emote-img" src={MadruckMad} height="500px" width="500px" alt="Madrucksack Mad" />
                        <img className="emote-img" src={MadruckPout} height="500px" width="500px" alt="Madrucksack Pout" />
                        <img className="emote-img" src={MadruckThreaten} height="500px" width="500px" alt="Madrucksack Threaten" />
                        <img className="emote-img" src={MinnieEhe} height="500px" width="500px" alt="Minnieful Ehe" />
                        <img className="emote-img" src={MinnieGremlin} height="500px" width="500px" alt="Minnieful Gremlin" />
                        <img className="emote-img" src={MinnieHype} height="500px" width="500px" alt="Minnieful Hype" />
                        <img className="emote-img" src={MinnieLove} height="500px" width="500px" alt="Minnieful Love" />
                        <img className="emote-img" src={MinniePog} height="500px" width="500px" alt="Minnieful Pog" />
                        <img className="emote-img" src={MinnieSmug} height="500px" width="500px" alt="Minnieful Smug" />
                        <img className="emote-img" src={RuneShrug} height="500px" width="500px" alt="RuneZephyroth Shrug" />
                        <img className="emote-img" src={RuneSigh} height="500px" width="500px" alt="RuneZephyroth Sigh" />
                        <img className="emote-img" src={RuneSleep} height="500px" width="500px" alt="RuneZephyroth Sleep" />
                    </Carousel>
                </div>
            </div>

            <h2 className="sub-section-title">Scuffed Emotes</h2>

            <div className="section-container">
                <div className="emote-carousel">
                    <Carousel
                        autoPlay={true}
                        infiniteLoop={true}
                        showStatus={false}
                        showIndicators={false}
                        stopOnHover={true}
                        centerSlidePercentage={true}
                    >
                        <img className="emote-img" src={TempestDespair} height="500px" width="500px" alt="Tempest Despair" />
                        <img className="emote-img" src={TempestBullied} height="500px" width="500px" alt="Tempest Bullied" />
                        <img className="emote-img" src={TempestLove} height="500px" width="500px" alt="Tempest Love" />
                        <img className="emote-img" src={TempestMadge} height="500px" width="500px" alt="Tempest Madge" />
                        <img className="emote-img" src={TempestSleep} height="500px" width="500px" alt="Tempest Sleep" />
                    </Carousel>
                </div>
            </div>

            <h2 className="sub-section-title">Chibi Pngtuber</h2>

            <div className="model-display-container">
                <div className="model-container">
                    <img id="model-img1" className="model-img" src={Chihiroo1} height="820px" width="750px" alt="Chihiroo 1" />
                    <img id="model-img2" className="model-img" src={Chihiroo2} height="820px" width="750px" alt="Chihiroo 2" />
                    <img id="model-img3" className="model-img" src={Chihiroo3} height="820px" width="750px" alt="Chihiroo 3" />
                    <img id="model-img4" className="model-img" src={Chihiroo4} height="820px" width="750px" alt="Chihiroo 4" />
                </div>

                <img className="animated-model" src={ChihirooAnimated} height="820px" width="750px" alt="Chihiroo Animated" />
            </div>

            <br />
            <br />
            <hr />
            <br />

            <div className="model-display-container">
                <img className="animated-model" src={MadruckAnimated} height="730px" width="600px" alt="Madrucksack Animated" />

                <div className="model-container">
                    <img id="model-img1" className="model-img" src={Madruck1} height="730px" width="600px" alt="Madrucksack 1" />
                    <img id="model-img2" className="model-img" src={Madruck2} height="730px" width="600px" alt="Madrucksack 2" />
                    <img id="model-img3" className="model-img" src={Madruck3} height="730px" width="600px" alt="Madrucksack 3" />
                    <img id="model-img4" className="model-img" src={Madruck4} height="730px" width="600px" alt="Madrucksack 4" />
                </div>
            </div>

            <br />
            <br />
            <hr />
            <br />

            <div className="model-display-container">
                <div className="model-container">
                    <img id="model-img1" className="model-img" src={Rune1} height="862px" width="600px" alt="RuneZephyroth 1" />
                    <img id="model-img2" className="model-img" src={Rune2} height="862px" width="600px" alt="RuneZephyroth 2" />
                    <img id="model-img3" className="model-img" src={Rune3} height="862px" width="600px" alt="RuneZephyroth 3" />
                    <img id="model-img4" className="model-img" src={Rune4} height="862px" width="600px" alt="RuneZephyroth 4" />
                </div>

                <img className="animated-model" src={RuneAnimated} height="862px" width="600px" alt="Runezephyroth Animated" />
            </div>
        </div>
    );
}

export default Portfolio;