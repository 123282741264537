import React, { useEffect, useState } from 'react';
import { About, Commissions, Portfolio, Support, TOS } from './components';
import { AnimatedBackground } from './assets';
import { DiscordLogo, TwitchLogo, TwitterLogo, YoutubeLogo } from './assets//socials';
import './App.css';

function App() {
    const currentPath = window.location.pathname === '/' ? '/home' : window.location.pathname.toLowerCase();
    const [path, setPath] = useState(currentPath);

    useEffect(() => {
        window.addEventListener('popstate', (event) => {
            setPath(event.target.location.pathname);
        });

        window.addEventListener('contextmenu', event => event.preventDefault());
    }, []);

    // useEffect(() => {
    //     const observer = new IntersectionObserver(entries => {
    //         // We will fill in the callback later...
    //         entries.forEach(entry => {
    //             if (entry.isIntersecting) {
    //                 // It's visible. Add the animation class here!
    //                 if (entry.target.className.includes('left'))
    //                     entry.target.classList.add('slide-left-anim');

    //                 if (entry.target.className.includes('right'))
    //                     entry.target.classList.add('slide-right-anim');
    //             }
    //         });
    //     });

    //     document.querySelectorAll('.slide-left').forEach(element => {
    //         observer.observe(element);
    //     });

    //     document.querySelectorAll('.slide-right').forEach(element => {
    //         observer.observe(element);
    //     });
    // }, [path]);

    const isCurrentTab = (newPath) => {
        return (newPath === '/home' && path === '/') || newPath === path;
    }

    const changePage = (newPath) => {
        window.history.pushState('', '', `${window.location.origin}${newPath}`);
        setPath(newPath);
    }

    const currentPage = () => {
        switch (currentPath) {
            case '/home':
                return <About />;
            case '/portfolio':
                return <Portfolio />;
            case '/commissions':
                return <Commissions />;
            case '/support':
                return <Support />;
            case '/tos':
                return <TOS />;
            default:
                return <About />;
        }
    }

    return (
        <div className="App">
            <div className="header">
                <video className="header-background" loop autoPlay muted playsInline>
                    <source src={AnimatedBackground} type="video/mp4" />
                </video>

                {/* <div className="header-credit">
                    <p>StarvingVenomXYZDragon</p>
                </div> */}
            </div>

            <div className="body">
                <div className="menu-container">
                    <button className={`menu-button ${isCurrentTab('/home') ? 'current-tab' : ''} menu-button-anim`} onClick={() => changePage('/home')}>
                        <span className="yellow">A</span>bout <span className="yellow">M</span>e
                    </button>
                    <button className={`menu-button ${isCurrentTab('/portfolio') ? 'current-tab' : ''} menu-button-anim`} onClick={() => changePage('/portfolio')}>
                        <span className="yellow">P</span>ortfolio
                    </button>
                    <button className={`menu-button ${isCurrentTab('/commissions') ? 'current-tab' : ''} menu-button-anim`} onClick={() => changePage('/commissions')}>
                        <span className="yellow">C</span>ommissions
                    </button>
                    <button className={`menu-button ${isCurrentTab('/tos') ? 'current-tab' : ''} menu-button-anim`} href="#TOS" onClick={() => changePage('/tos')}>
                        <span className="yellow">T</span>erms <span className="yellow">o</span>f <span className="yellow">S</span>ervice
                    </button>
                    <button className={`menu-button ${isCurrentTab('/support') ? 'current-tab' : ''} menu-button-anim`} onClick={() => changePage('/support')}>
                        <span className="yellow">S</span>upport
                    </button>
                </div>

                <div id="menuToggle">
                    <input type="checkbox" />
                    <span className="hamburger top"></span>
                    <span className="hamburger center"></span>
                    <span className="hamburger bottom"></span>

                    <ul id="menu">
                        <li>
                            <a onClick={() => changePage('/home')}>
                                <p className={`menu-button ${isCurrentTab('/home') ? 'current-tab' : ''} menu-button-anim`}>
                                    <span className="yellow">A</span>bout <span className="yellow">M</span>e
                                </p>
                            </a>
                        </li>
                        <li>
                            <a onClick={() => changePage('/portfolio')}>
                                <p className={`menu-button ${isCurrentTab('/portfolio') ? 'current-tab' : ''} menu-button-anim`}>
                                    <span className="yellow">P</span>ortfolio
                                </p>
                            </a>
                        </li>
                        <li>
                            <a onClick={() => changePage('/commissions')}>
                                <p className={`menu-button ${isCurrentTab('/commissions') ? 'current-tab' : ''} menu-button-anim`}>
                                    <span className="yellow">C</span>ommissions
                                </p>
                            </a>
                        </li>
                        <li>
                            <a onClick={() => changePage('/tos')}>
                                <p className={`menu-button ${isCurrentTab('/tos') ? 'current-tab' : ''} menu-button-anim`}>
                                    <span className="yellow">T</span>erms <span className="yellow">o</span>f <span className="yellow">S</span>ervice
                                </p>
                            </a>
                        </li>
                        <li>
                            <a onClick={() => changePage('/support')}>
                                <p className={`menu-button ${isCurrentTab('/support') ? 'current-tab' : ''} menu-button-anim`}>
                                    <span className="yellow">S</span>upport
                                </p>
                            </a>
                        </li>
                    </ul>
                </div>

                <div className="body-container">
                    {currentPage()}
                    {/* <About />
                    <Portfolio />
                    <Commissions />
                    <TOS />
                    <Support /> */}
                </div>

                <div className="footer">
                    <a className="footer-logo" href="https://www.twitch.tv/minnieful" target="_blank" rel="noopener noreferrer">
                        <img className="footer-logo" src={TwitchLogo} height="50px" width="50px" alt="Twitch" />
                    </a>
                    <a className="footer-logo" href="https://twitter.com/Minnieful1" target="_blank" rel="noopener noreferrer">
                        <img className="footer-logo" src={TwitterLogo} height="50px" width="50px" alt="Twitter" />
                    </a>
                    <a className="footer-logo" href="https://discord.gg/XdQb8hVTTq" target="_blank" rel="noopener noreferrer">
                        <img className="footer-logo" src={DiscordLogo} height="50px" width="50px" alt="Discord" />
                    </a>
                    <a className="footer-logo" href="https://www.youtube.com/@minnieful546" target="_blank" rel="noopener noreferrer">
                        <img className="footer-logo" src={YoutubeLogo} height="50px" width="50px" alt="Youtube" />
                    </a>
                </div>
            </div>
        </div>
    );
}

export default App;