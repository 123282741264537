import {
    MinnieOutfit1Pose1Crying, MinnieOutfit1Pose1Disgusted, MinnieOutfit1Pose1Happy, MinnieOutfit1Pose1Neutral,
    MinnieOutfit1Pose1Pout, MinnieOutfit1Pose1Shock, MinnieOutfit1Pose1Tongue, MinnieOutfit1Pose1Uwu, MinnieOutfit1Pose1Yandere,
    MinnieOutfit1Pose2Crying, MinnieOutfit1Pose2Disgusted, MinnieOutfit1Pose2Happy, MinnieOutfit1Pose2Neutral,
    MinnieOutfit1Pose2Pout, MinnieOutfit1Pose2Shock, MinnieOutfit1Pose2Tongue, MinnieOutfit1Pose2Uwu, MinnieOutfit1Pose2Yandere,
    MinnieOutfit2Pose1Crying, MinnieOutfit2Pose1Disgusted, MinnieOutfit2Pose1Happy, MinnieOutfit2Pose1Neutral,
    MinnieOutfit2Pose1Pout, MinnieOutfit2Pose1Shock, MinnieOutfit2Pose1Tongue, MinnieOutfit2Pose1Uwu, MinnieOutfit2Pose1Yandere,
    MinnieOutfit2Pose2Crying, MinnieOutfit2Pose2Disgusted, MinnieOutfit2Pose2Happy, MinnieOutfit2Pose2Neutral,
    MinnieOutfit2Pose2Pout, MinnieOutfit2Pose2Shock, MinnieOutfit2Pose2Tongue, MinnieOutfit2Pose2Uwu, MinnieOutfit2Pose2Yandere
} from './../assets/profiles';
import './../Profile.css';
import './../App.css';

function About() {
    return (
        <div id="about" className="about-container">
            <div className="profile-container">
                <img id="img1" className="profile-img" src={MinnieOutfit1Pose2Happy} height="800px" width="472px" alt="Minnieful Outfit 1 Happy 1" />
                <img id="img2" className="profile-img" src={MinnieOutfit2Pose1Crying} height="800px" width="472px" alt="Minnieful Outfit 2 Crying 2" />
                <img id="img3" className="profile-img" src={MinnieOutfit1Pose1Disgusted} height="800px" width="472px" alt="Minnieful Outfit 1 Disgusted 2" />
                <img id="img4" className="profile-img" src={MinnieOutfit2Pose2Neutral} height="800px" width="472px" alt="Minnieful Outfit 2 Neutral 1" />
                <img id="img5" className="profile-img" src={MinnieOutfit1Pose2Pout} height="800px" width="472px" alt="Minnieful Outfit 1 Pout 1" />
                <img id="img6" className="profile-img" src={MinnieOutfit2Pose1Shock} height="800px" width="472px" alt="Minnieful Outfit 2 Shock 2" />
                <img id="img7" className="profile-img" src={MinnieOutfit1Pose1Tongue} height="800px" width="472px" alt="Minnieful Outfit 1 Tongue 2" />
                <img id="img8" className="profile-img" src={MinnieOutfit2Pose2Yandere} height="800px" width="472px" alt="Minnieful Outfit 2 Yandere 1" />
                <img id="img9" className="profile-img" src={MinnieOutfit1Pose2Uwu} height="800px" width="472px" alt="Minnieful Outfit 1 Uwu 1" />
                <img id="img10" className="profile-img" src={MinnieOutfit2Pose1Happy} height="800px" width="472px" alt="Minnieful Outfit 2 Happy 2" />
                <img id="img11" className="profile-img" src={MinnieOutfit1Pose1Crying} height="800px" width="472px" alt="Minnieful Outfit 1 Crying 2" />
                <img id="img12" className="profile-img" src={MinnieOutfit2Pose2Disgusted} height="800px" width="472px" alt="Minnieful Outfit 2 Disgusted 1" />
                <img id="img13" className="profile-img" src={MinnieOutfit1Pose2Neutral} height="800px" width="472px" alt="Minnieful Outfit 1 Neutral 1" />
                <img id="img14" className="profile-img" src={MinnieOutfit2Pose1Pout} height="800px" width="472px" alt="Minnieful Outfit 2 Pout 2" />
                <img id="img15" className="profile-img" src={MinnieOutfit1Pose1Shock} height="800px" width="472px" alt="Minnieful Outfit 1 Shock 2" />
                <img id="img16" className="profile-img" src={MinnieOutfit2Pose2Tongue} height="800px" width="472px" alt="Minnieful Outfit 2 Tongue 1" />
                <img id="img17" className="profile-img" src={MinnieOutfit1Pose2Yandere} height="800px" width="472px" alt="Minnieful Outfit 1 Yandere 1" />
                <img id="img18" className="profile-img" src={MinnieOutfit2Pose1Uwu} height="800px" width="472px" alt="Minnieful Outfit 2 Uwu 2" />
                <img id="img19" className="profile-img" src={MinnieOutfit1Pose1Happy} height="800px" width="472px" alt="Minnieful Outfit 1 Happy 2" />
                <img id="img20" className="profile-img" src={MinnieOutfit2Pose2Crying} height="800px" width="472px" alt="Minnieful Outfit 2 Crying 1" />
                <img id="img21" className="profile-img" src={MinnieOutfit1Pose2Disgusted} height="800px" width="472px" alt="Minnieful Outfit 1 Disgusted 1" />
                <img id="img22" className="profile-img" src={MinnieOutfit2Pose1Neutral} height="800px" width="472px" alt="Minnieful Outfit 2 Neutral 2" />
                <img id="img23" className="profile-img" src={MinnieOutfit1Pose1Pout} height="800px" width="472px" alt="Minnieful Outfit 1 Pout 2" />
                <img id="img24" className="profile-img" src={MinnieOutfit2Pose2Shock} height="800px" width="472px" alt="Minnieful Outfit 2 Shock 1" />
                <img id="img25" className="profile-img" src={MinnieOutfit1Pose2Tongue} height="800px" width="472px" alt="Minnieful Outfit 1 Tongue 1" />
                <img id="img26" className="profile-img" src={MinnieOutfit2Pose1Yandere} height="800px" width="472px" alt="Minnieful Outfit 2 Yandere 2" />
                <img id="img27" className="profile-img" src={MinnieOutfit1Pose1Uwu} height="800px" width="472px" alt="Minnieful Outfit 1 Uwu 2" />
                <img id="img28" className="profile-img" src={MinnieOutfit2Pose2Happy} height="800px" width="472px" alt="Minnieful Outfit 2 Happy 1" />
                <img id="img29" className="profile-img" src={MinnieOutfit1Pose2Crying} height="800px" width="472px" alt="Minnieful Outfit 1 Crying 1" />
                <img id="img30" className="profile-img" src={MinnieOutfit2Pose1Disgusted} height="800px" width="472px" alt="Minnieful Outfit 2 Disgusted 2" />
                <img id="img31" className="profile-img" src={MinnieOutfit1Pose1Neutral} height="800px" width="472px" alt="Minnieful Outfit 1 Neutral 2" />
                <img id="img32" className="profile-img" src={MinnieOutfit2Pose2Pout} height="800px" width="472px" alt="Minnieful Outfit 2 Pout 1" />
                <img id="img33" className="profile-img" src={MinnieOutfit1Pose2Shock} height="800px" width="472px" alt="Minnieful Outfit 1 Shock 1" />
                <img id="img34" className="profile-img" src={MinnieOutfit2Pose1Tongue} height="800px" width="472px" alt="Minnieful Outfit 2 Tongue 2" />
                <img id="img35" className="profile-img" src={MinnieOutfit1Pose1Yandere} height="800px" width="472px" alt="Minnieful Outfit 1 Yandere 2" />
                <img id="img36" className="profile-img" src={MinnieOutfit2Pose2Uwu} height="800px" width="472px" alt="Minnieful Outfit 2 Uwu 1" />
            </div>

            <div className="about-text">
                <div className="padding">
                    <h1 className="section-title">About</h1>

                    <p className="slide-left">
                        Minnieful is the lowest ranking angel. THE lowest ranking angel.
                    </p>
                    <p className="slide-left">
                        She has small wings and barely has any responsibilities due no one in Heaven trusting her with any important tasks.
                        She rarely pays attention during bible study, drops the offerings during service and ignores most tasks angels usually do in Heaven.
                    </p>
                    <p className="slide-left">
                        One thing God did want Minnie to do was to spread love and friendship... which Minnie is doing… but uh… through streaming and playing video games all day!
                        <br />
                        <span className="sm-font">
                            (Probably not what he had in mind)
                        </span>
                    </p>
                    <p className="slide-left">
                        Minnie does occasionally visit other worlds and makes friends everywhere she goes! Whether they are humans, foxes, monsters, demi-humans, ghosts and demons/half-demons!
                        <br />
                        <span className="sm-font">
                            (Don't tell God Minnie is making friends with demons...)
                        </span>
                    </p>
                    <p className="slide-left">
                        Everywhere she goes, she makes sure to spread happiness and joy to the hearts of everyone by making life... MINNIEFUL! <span className="sm-font">(cringe)</span>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default About;