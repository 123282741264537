import './../App.css';

function Commissions() {
    return (
        <div id="commissions" className="commissions-container">
            <h1 className="section-title">Commissions</h1>

            <h2 className="sub-section-title">Pngtuber</h2>

            <div className="section-container">
                <div className="commission-list">
                    <p className="bold-temp">
                        Chibi:
                    </p>
                    <p>
                        A thigh-up body chibi pngtuber.
                        <br />
                        Includes blinking and talking.
                    </p>
                    <p>
                        <span className="bold-temp">Price:</span> $65 USD
                        <br />
                        <span className="sm-font">(Prices may vary on complexity)</span>
                        <br />
                        <br />
                        <span className="bold-temp">Extras:</span>
                        <br />
                        $5 per expression.
                    </p>
                </div>
            </div>

            <h2 className="sub-section-title">Emotes</h2>


            <div className="section-container">
                <div className="commission-list">
                    <p className="bold-temp">
                        Chibi:
                    </p>
                    <p>
                        A png drawing of a character of your choosing.
                        <br />
                        I do not make animated emotes.
                    </p>
                    <p>
                        <span className="bold-temp">Price:</span> $20 USD
                        <br />
                        <span className="sm-font">(Prices may vary on complexity)</span>
                    </p>
                </div>

                <hr />

                <div className="commission-list">
                    <p className="bold-temp">
                        Scuffed:
                    </p>
                    <p>
                        You get a quick and easy made emote.
                        <br />
                        It's super cheap and very scuffed.
                    </p>
                    <p>
                        <span className="bold-temp">Price:</span> $5 USD
                    </p>
                </div>
            </div>

            <h2 className="sub-section-title">Sketches (experimental)</h2>

            <div className="commission-list">
                <p className="bold-temp">
                    Frontal view (bust):
                </p>
                <p>
                    A frontal view of any character of your choosing.
                    <br />
                    You give the guidelines on what you want, and I'll use different techniques to create it.
                </p>
                <p>
                    <span className="bold-temp">Price:</span> $25 USD
                    <br />
                    <span className="sm-font">(Prices may vary on complexity)</span>
                </p>
            </div>

            <h2 className="sub-section-title">Custom Orders</h2>

            <div className="commission-list">
                <p>
                    Please message me on Discord about custom orders that are not offered here.
                </p>
            </div>
        </div>
    );
}

export default Commissions;